<template>
  <div class="trackBlock">
    <transition name="track-panel" mode="out-in" @before-enter="isAnimating=true" @before-leave="isAnimating=true"
      @after-enter="isAnimating=false" @after-leave="isAnimating=false">
      <div class="v-nav" v-if="isOnSearch" :class="$i18n.locale" key="tree">
        <tree-nav @return="isOnSearch = !isOnSearch" :show-return="true" @click-device="clickDevice" :cache="false"
          @click-batch="p => clickDevice(0, p[0])"></tree-nav>
      </div>
      <div class="v-track" key="panel" :class="$i18n.locale" v-else>
        <el-scrollbar ref="elScroll" style="height:100%;">
          <div class="searchBox">
            <div class="enter">
              <el-autocomplete @dblclick.native="autoDbClick" prefix-icon="el-icon-search" @blur="autoFocusOut"
                v-model="group.vehicle.VehicleNum" :fetch-suggestions="fetchVehicleList" popper-class="v-popper-vehicle"
                :placeholder="$t('commonListVehicle.searchVehicle')" @select="selectChange" ref="autoSearch"
                :trigger-on-focus="false" :debounce="600" size="small">
                <template slot-scope="{item}">
                  <p class="vehicle" @click.prevent v-if="item.DeviceRowIndex === 1">
                    <template v-if="item.TypeID===1">
                      <i-icon name="iconuser-person-copy" style="flex: 0 0 16px;"></i-icon>
                    </template>
                    <template v-if="item.TypeID===2">
                      <i-icon name="iconuser-airte-copy" style="flex: 0 0 16px;"></i-icon>
                    </template>
                    <template v-if="item.TypeID===0">
                      <i-icon name="iconuser-car-copy" style="flex: 0 0 16px;"></i-icon>
                    </template>
                    {{item.VehicleName}}</p>
                  <p class="device">
                    <i-icon name="icon-crosshairs-gps"></i-icon> {{item.SIM2||item.SIM}}
                    <span class="device__tip" v-if="!item.RcvTime">{{$t('commonListVehicle.unused')}}</span>
                  </p>
                </template>
              </el-autocomplete>
            </div>
            <div class="btn">
              <span v-tooltip="$t('onlineTrack.searchVehicle')" @click="isOnSearch = !isOnSearch">
                <i-icon name="iconliebiao"></i-icon>
              </span>
            </div>
          </div>
          <div class="searchResult">
            <div class="li">
              <span v-for="item in group.devices" :key="item.ObjectID"
                :title="item.SIM2">{{item.SIM2}}【{{item.TrackerType == 0 ? $t('dict')['有线'] : $t('dict')['无线']}}】</span>
            </div>
          </div>
          <div class="searchOptions">
            <span @click="setRangeToday" class="i-link">{{$t('onlineTrack.today')}}</span>
            <span @click="setRangeYesterday">{{$t('onlineTrack.yestoday')}}</span>
            <span @click="setRangeBeforeYesterday">{{$t('onlineTrack.beforeYestoday')}}</span>
          </div>
          <div class="moreOptions">
            <div class="items">
              <div class="item_title">{{$t('onlineTrack.startTime')}}</div>
              <div class="item_text item_date">
                <el-date-picker type="datetime" size="small" :clearable="false" format="yyyy-MM-dd HH:mm"
                  v-model="setting.startTime" :picker-options='pickerOptions' :placeholder="$t('common.selectDate')">
                </el-date-picker>
              </div>
            </div>
            <div class="items">
              <div class="item_title">{{$t('onlineTrack.endTime')}}</div>
              <div class="item_text item_date">
                <el-date-picker type="datetime" size="small" :clearable="false" format="yyyy-MM-dd HH:mm"
                  v-model="setting.endTime" :picker-options='pickerOptions' :placeholder="$t('common.selectDate')">
                </el-date-picker>
              </div>
            </div>
            <div class="items" v-if="isShowMoreOptions">
              <div class="item_title">{{$t('onlineTrack.longStop')}}</div>
              <div class="item_text">
                <el-input v-model.trim="setting.longStopMinutes" :maxlength="4" size="small"
                  :placeholder="$t('common.inputText')" oninput="value=value.replace(/[^\d]/g, '')">
                  <span slot="suffix">{{$t('onlineTrack.minute')}}</span>
                </el-input>
                <el-checkbox v-model="isAddStopMarker" v-if="setting.longStopMinutes && setting.longStopMinutes != '0'">
                  {{$t('onlineTrack.showStopFlag')}}</el-checkbox>
              </div>
            </div>
            <div class="items" v-if="setting.lineMode===2 || setting.lineMode === 3">
              <div class="item_title">{{$t('onlineTrack.lowAndHigh')}}：</div>
              <div class="item_text">
                <el-slider v-model="setting.speedRange" :min="1" :disabled="isShowRealScene" range :max="130"
                  :format-tooltip="formatSpeed"></el-slider>
              </div>
            </div>
            <div class="items">
              <div class="item_title">{{$t('onlineTrack.other')}}</div>
              <div class="item_text">
                <p>
                  <el-radio v-model="setting.lineMode" :label="1">{{$t('onlineTrack.lineMode')}}</el-radio>
                </p>
                <p>
                  <el-radio v-model="setting.lineMode" :label="2">{{$t('onlineTrack.pointMode')}}</el-radio>
                </p>
                <p>
                  <el-checkbox v-model="setting.showDetail">{{$t('onlineTrack.historyDetail')}}</el-checkbox>
                </p>
                <tool-tip :content="$t('onlineTrack.realSenceTip')" :open-dealy="500">
                  <p v-if="$i18n.locale==='zh'">
                    <el-checkbox v-model="isShowRealScene">{{$t('onlineTrack.showRealSence')}}</el-checkbox>
                  </p>
                </tool-tip>
                <p v-if="itemId && currentTrackType !== 1">
                  <el-checkbox v-model="isShowRightNav">{{$t('onlineTrack.viewTrip')}}</el-checkbox>
                </p>
                <transition name="breadcrumb">
                  <div v-if="isShowMoreOptions">
                    <p v-if="currentTrackType !== 1">
                      <el-checkbox v-model="setting.showPoint">{{$t('onlineTrack.showPoint')}}</el-checkbox>
                    </p>
                    <tool-tip :content="$t('onlineTrack.colorTip')" :open-dealy="500">
                      <p v-if="setting.lineMode===2">
                        <el-checkbox v-model="setting.showColor">{{$t('onlineTrack.showColor')}}</el-checkbox>
                      </p>
                    </tool-tip>
                    <p v-if="setting.lineMode===2">
                      <el-checkbox v-model="showPath">{{$t('onlineTrack.showPath')}}</el-checkbox>
                    </p>
                    <p v-if="currentTrackType !== 1">
                      <el-checkbox v-model="setting.hideZeroSpeed">{{$t('onlineTrack.hideZero')}}</el-checkbox>
                    </p>
                    <p v-if="currentTrackType !== 1">
                      <el-checkbox v-model="setting.referLocation">{{$t('onlineTrack.referPosition')}}</el-checkbox>
                    </p>
                    <p>
                      <el-checkbox v-model="setting.showAddress">{{$t('onlineTrack.showAddress')}}</el-checkbox>
                    </p>
                    <p>
                      <el-checkbox v-model="isTrackInfoOpen">{{$t('onlineTrack.showInfo')}}</el-checkbox>
                    </p>
                    <p>
                      <el-checkbox v-model="showHomeAndCompanyPos">{{$t('onlineTrack.homeAndCom')}}</el-checkbox>
                    </p>
                  </div>
                </transition>
              </div>
            </div>
            <div class="hr"><span @click="()=>{ isShowMoreOptions =!isShowMoreOptions }"
                :class="isShowMoreOptions?'on':''">
                <i-icon name="iconxiangxia"></i-icon>
              </span></div>


          </div>


        </el-scrollbar>
      </div>
    </transition>
    <div class="content">
      111
    </div>
    {{objectId}}
  </div>
</template>

<script>
  import {
    VclCode
  } from 'vue-content-loading'
  import miment from 'miment'
  import TreeNav from '@/views/common/tree-nav'
  import {
    ConvertFrom,
    QueryTreeComprehenList
  } from '@/api/common'

  const PLAY = 'PLAY'
  const PAUSE = 'PAUSE'
  const STOP = 'STOP'
  export default {
    name: 'onlineTrack',
    props: {
      itemId: {
        type: Number,
        default: null
      }
    },
    components: {
      VclCode,
      TreeNav
    },
    data() {
      return {
        miment,
        map: null,
        objectId: null,
        isOnSearch: false, // 打开搜索设备
        group: {
          vehicle: {},
          people: {},
          devices: [],
          holdInfo: {}
        },
        setting: {
          lineMode: 1,
          startTime: miment().set(0, 'hh').set(0, 'mm').set(0, 'ss'),
          endTime: miment(),
          lowSpeed: 30,
          highSpeed: 60,
          longStopMinutes: 0,
          showPoint: false,
          hideZeroSpeed: true,
          referLocation: false,
          isBlodLine: true,
          showDetail: true,
          showAddress: false,
          playSpeed: 50,
          speedRange: [30, 60],
          showColor: false
        },
        isShowMoreOptions: true,
        isAddStopMarker: false,
        pickerOptions: {
          cellClassName: (date) => {
            let times = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
            if (this.trackDates.includes(times)) {
              return 'tagRed';
            }
          }
        },
        trackDates: []
      }
    },
    watch: {
      itemId(val) {
        this.objectId = val
      },

    },
    computed: {

    },
    methods: {
      clickDevice(item) {
        this.isOnSearch = false
        this.objectId = item.ObjectID
      },
      selectChange(item) {
        this.clickDevice(item)
      },
      autoDbClick() {
        // console.log('dbclick')
        this.$refs.autoSearch.activated = true
        this.$refs.autoSearch.suggestionDisabled = false
        this.$refs.autoSearch.debouncedGetData(this.$refs.autoSearch.value)
      },
      autoFocusOut() {
        if (this.$refs.autoSearch.value) {
          this.$refs.autoSearch.suggestionDisabled = true
        }
      },
      fetchVehicleList(queryKey, cb) {
        const promise = new Promise((resolve) => {
          QueryTreeComprehenList(queryKey, null).then(ret => {
            cb && cb(ret.data.slice().map(k => Object.assign(k, {
              value: k.VehicleName
            })))
            resolve(ret.data)
          })
        })
        return promise
      },
    },
    created() {
      // this.currentRow = null
      // this.PLAY = PLAY
      // this.PAUSE = PAUSE
      // this.STOP = STOP
      // this.objectId = this.itemId
      // this.fetchData()
      // let onMouseUp = () => {
      //   this.isOnMouseDown = false
      // }
      // window.addEventListener('mouseup', onMouseUp, null)
      // this.$once('hook:beforeDestroy', () => {
      //   window.removeEventListener('mouseup', onMouseUp)
      //   clearTimeout(this.timer_play_point)
      // })
      // this.pointOverLays = []
      // this.pointOverLays2 = []
      // this.lineOverLays = []
      // this.tempOverLays = []
      // this.tempOverLays2 = []
      // this.getPanel = throttle(this.getPanelImmediate, 333)
      // this.getPanel2 = throttle(this.getPanelImmediate2, 333)
    },
    mounted() {
      // this.initMap().then(()=>{
      //   this.routeEnterHandler()
      //   this.fetchVehicleAddress().then(res=>{
      //     this.addBeginMarker(res)
      //     this.initRealScene(res[0].Lon,res[0].Lat)
      //   })
      // })
    },
    // mixins:[
    //   mixinMapCachePosition,
    //   mixinOnlineTrack
    // ]
  }

</script>

<style lang="scss" scoped>
  .trackBlock {
    display: -ms-flexbox;
    display: flex;
    height: calc(100vh - 48px);
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    overflow-x: hidden;

    .v-nav {
      width: auto;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      position: relative;
      z-index: 119;
    }

    .v-track {
      width: 260px;
      height: 100%;

      .searchBox {
        display: flex;
        margin-top: 10px;
        padding: 0 12px;
        font-size: 14px;

        .enter {
          position: relative;
          z-index: 1;

          & ::v-deep .el-input__inner {
            border-radius: 20px;
          }
        }

        .btn {
          width: 50px;
          font-size: 20px;
          padding-top: 5px;
          text-align: center;
          color: #1890ff;
          position: relative;
          left: 12px;
        }
      }

      .searchResult {
        margin-top: 10px;

        .li {}
      }

      .searchOptions {
        display: flex;
        justify-content: flex-end;

        span {
          text-align: center;
          font-size: 12px;
          color: #5096ff;
          padding: 6px 5px;
          border-radius: 20px;
          margin-right: 5px;
          cursor: pointer;
          text-decoration: none;
        }
      }

      .moreOptions {
        padding: 0 10px;

        .items {
          display: flex;
          margin-top: 10px;
          font-size: 14px;

          .item_title {
            height: 32px;
            line-height: 32px;
            width: 72px;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #303133;
            white-space: nowrap;
          }

          .item_text {
            flex-grow: 1;
            width: 0;
            min-height: 32px;
            line-height: 32px;
            color: #606266;

            .el-date-editor {
              width: 100%;
            }

            & ::v-deep .el-input__inner {
              border-radius: 20px;
            }

            & ::v-deep .el-input__prefix {
              display: none;
            }

            & ::v-deep .el-input__prefix {
              border-radius: 20px;
            }
          }

          .item_date {
            & ::v-deep .el-input__inner {
              border-radius: 20px;
              padding: 0 12px;
            }
          }
        }

        .hr {
          width: 90%;
          height: 10px;
          margin: 0 auto 30px;
          border-bottom: 1px solid #ccc;
          position: relative;
          z-index: 1;

          span {
            font-size: 36px;
            background-color: #fff;
            position: absolute;
            left: 50%;
            margin-left: -25px;
            top: -8px;
            z-index: 1;
            transition: transform .5s;
          }

          .on {
            transform: rotate(180deg);
          }
        }
      }
    }

    .content {
      width: 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-positive: 1;
      flex-grow: 1;
      position: relative;
      z-index: 1;
    }
  }

</style>
